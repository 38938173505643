<template>
  <div>
    <div class="aboutOurCompany">
      <div class="title"><h3>فــروع الشــركــة</h3></div>
      <v-divider></v-divider>
      <v-container>
        <v-row>
            <h4 class="blue--text">
            شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
          </h4>
          <span class="ml-2 mr-2">/</span
          ><span class="gray--text"> فـروع الشركة</span>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12">
            <div>
              <div>
                <h2>فروع الشركه</h2>
                   <!--   <iframe src="https://www.google.com/maps/place/Company+water+and+sanitation+in+Assiut/@27.1734471,31.20436,17.25z/data=!4m5!3m4!1s0x14450b972d0fb135:0xcfbbd00ecfc7e476!8m2!3d27.1729459!4d31.2030575" width="1100" height="650" style=" border: 5px solid gray;" allowfullscreen="" loading="lazy"></iframe> -->
                 <!--     <iframe id="if1" width="100%" height="254" style="visibility:visible" src="http://www.google.com/custom?q=&btnG=Search"></iframe>-->
        <GoogleMaps> </GoogleMaps>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            lg="4"
            md="6"
            v-for="(branchItem, i) in branchOfCompany"
            :key="i"
          >
            <v-card class="mx-auto" max-width="344" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
            <!--  <p> <v-icon color="green">phone</v-icon>/{{ branchItem.phone }}</p>-->
                <p> <v-icon color="blue">mdi-fax</v-icon>/{{ branchItem.fax }}</p>
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ branchItem.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    branchItem.address
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar tile size="80">
                  <img alt="user" src="@/assets/Images/ascww-logo.png"
                /></v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                  v-bind="{ 'font-weight': 'bold' }"
                  @click="changePostion(branchItem)"
                >
                  بـحــث
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import GoogleMaps from "../../components/GoogleMaps.vue";

export default {
  name: "GoogleMap",
  components: {
   GoogleMaps,
  },

  data() {
    return {
      center: { lat: 27.222235281679712, lng: 31.194109557433396 },
    };
  },
  methods: {
    changePostion(mbranchItem) {
      this.$alert(mbranchItem.name + "/" + mbranchItem.phone);
    },
  },
  computed: {
    branchOfCompany() {
      return this.$store.state.locations;
    },
  },
  props: ["username"],
};
</script>
<style scoped>
iframe{
  border:2px solid black;
}
</style>
