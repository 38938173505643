<template>
  <div class="mapsAscww">
    <gmap-map :center="center" :zoom="zoom" language="ar" ref="map">
      <gmap-marker
        :key="index"
        v-for="(location, index) in locations"
        :position="{ lat: location.lat, lng: location.lng }"
        :clickable="true"
        @click="openInfoWindow(location);"
      />
      <gmap-info-window
        v-if="selectedLocation !== null"
        :position="{ lat: selectedLocation.lat, lng: selectedLocation.lng }"
        :opened="infoBoxOpen"
        @closeclick="infoBoxOpen = false;"
      >
        <div class="infoWindow" style="width: 300px;">
          <button @click="closeInfoWindow();"><v-icon>close</v-icon></button>
          <h2 id="infoWindow-location" class="gray--text">{{ selectedLocation.name }}</h2>
          <h3><span>العنوان :</span>{{selectedLocation.address}}</h3>
          <h4><span>التليفون :</span>{{selectedLocation.phone}}</h4>
          <h4><span>الفاكس :</span>{{selectedLocation.fax}}</h4>
      
        </div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
export default {
  data: () => ({
    zoom: 8,
    center: { lat:27.222235281679712, lng: 31.194109557433396 },
    selectedLocation: null,
    infoBoxOpen: false,

    
    
    
  }),
  methods: {
    openInfoWindow(location) {
    
      this.selectedLocation = location;
      this.infoBoxOpen = true;
    },
    closeInfoWindow() {
      this.infoBoxOpen = false;
    },
  
  },
  computed:{
     
    locations() {
      return this.$store.state.locations;
    },
  }

  
};
</script>

<style>
.vue-map-container {
  height: 640px;
}
.mapsAscww{
  box-shadow: 2px 2px 2px  gray;
}
</style>